import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../components/Dashboard.vue'
import PrivacyView from '../components/PrivacyView.vue'
import SupportView from '@/components/SupportView.vue'
import HomeView from '@/views/HomeView.vue'
import CourseDetailView from '@/views/CourseDetailView.vue'
import LoginView from '@/views/LoginView.vue'
import SignUpView from '@/views/SignUpView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }, 
  {
    path: '/course/:id?',
    name: 'course',
    component: CourseDetailView
  }, 
  {
    path: '/add',
    name: 'add',
    component: DashboardView
  }, 
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }, 
  {
    path: '/signup',
    name: 'signup',
    component: SignUpView
  }, 
  {
    path: '/privacy', 
    name: 'privacy', 
    component: PrivacyView
  }, 
  {
    path: '/support', 
    name: 'support', 
    component: SupportView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
